import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero2 from "../sections/heroes/Hero2"
import Feature2 from "../sections/features/Feature2"
import Support1 from "../sections/supports/Support1"
import Brand2 from "../sections/brands/Brand2"
import Testimonial2 from "../sections/testimonials/Testimonial2"
import Approach1 from "../sections/approaches/Approach1"
import Pricing1 from "../sections/pricings/Pricing1"
import Faq1 from "../sections/faqs/Faq1"
import Cta2 from "../sections/ctas/Cta2"

const HomePage2 = ({ data }) => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          menuPosition: "left",
          className: "!bg-transparent",
          onScrollClassName: "!bg-neutral-100/95",
        }}
        footerConfig={{
          hasTop: true,
          hasBottomSocial: true,
          bottomClassName: "bg-primary-25",
        }}
      >
        <GatsbySeo
          title={`Kloft - Home 02`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <Hero2 />
        <Feature2 />
        <Support1 />
        <Brand2 />
        <Testimonial2 />
        <Approach1 />
        <div id="Pricing1">
          <Pricing1 />
        </div>

        <Faq1 />
        <Cta2 />
      </PageWrapper>
    </>
  )
}

export default HomePage2

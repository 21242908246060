import React from "react"
import { Alarm, Calendar2, Flower2 } from "react-bootstrap-icons"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import ApproachCard1 from "../../components/cards/ApproachCard1 "

const Approache1 = () => {
  return (
    <>
      <Section className="bg-neutral-50">
        <Container>
          {/* section-wrap */}
          <div className="grid grid-cols-1 justify-center items-center laptop:grid-cols-2 gap-16 laptop:gap-[104px]">
            {/* left-part */}
            <div className="">
              <div data-aos="fade-up">
                <SectionTitle>
                  Our approach to helping businesses{" "}
                  <span className="text text-primary-600">grow</span>
                </SectionTitle>
                <SectionText className="max-w-[494px]">
                  Keeping your eye on the ball while performing a deep dive on
                  the start-up mentality to derive convergence on cross-platform
                  integration.
                </SectionText>

                <Link to="/contact-1">
                  <Button size={"xl"}>Get started now</Button>
                </Link>
              </div>
            </div>

            {/* right-part */}
            <div className="">
              {/* cards */}
              <div className="max-w-[504px] mx-auto gap-6 flex flex-col">
                {/* card */}

                <ApproachCard1
                  title="Visualize & plan"
                  text="Credibly reintermediate backend ideas for cross-platform
                      models. Continually reintermediate."
                  icon={<Alarm />}
                ></ApproachCard1>

                <ApproachCard1
                  title="Collaborate on designs"
                  text="Dynamically innovate resource-leveling customer service for state of the art customer service."
                  icon={<Flower2 />}
                ></ApproachCard1>

                <ApproachCard1
                  title="Daily standup"
                  text="integrated processes through technically sound intellectual capital."
                  icon={<Calendar2 />}
                ></ApproachCard1>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Approache1

import React from "react"
import { CameraVideo, Telephone } from "react-bootstrap-icons"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import ButtonArrow from "../../components/ButtonArrow"
import TextSmall from "../../components/TextSmall"

const Support1 = () => {
  return (
    <>
      <Section className="bg-neutral-50">
        <Container>
          {/* section-wrap */}
          <div className="grid grid-cols-1 gap-0 laptop:grid-cols-2">
            {/* left-part */}
            <div className="">
              <div className="relative inline-block">
                <StaticImage
                  src="../../images/world-map.png"
                  alt=""
                  className=""
                />
                <div>
                  <div
                    className="absolute inline-block top-1/4 left-1/4"
                    data-aos="zoom-in"
                  >
                    <StaticImage
                      src="../../images/support-avatar-1.png"
                      alt=""
                      className="object-cover border-2 border-white rounded-full w-9 h-9 tablet:w-12 tablet:h-12 "
                    />
                  </div>
                  <div
                    data-aos="zoom-in"
                    data-aos-delay="800"
                    className="absolute inline-block top-1/4 right-1/4"
                  >
                    <StaticImage
                      src="../../images/support-avatar-2.png"
                      alt=""
                      className="object-cover border-2 border-white rounded-full w-9 h-9 tablet:w-12 tablet:h-12 "
                    />
                  </div>
                  <div
                    data-aos="zoom-in"
                    data-aos-delay="1200"
                    className="absolute inline-block top-1/2 left-1/2"
                  >
                    <StaticImage
                      src="../../images/support-avatar-3.png"
                      alt=""
                      className="object-cover -translate-x-1/2 -translate-y-1/2 border-2 border-white rounded-full w-9 h-9 tablet:w-12 tablet:h-12 "
                    />
                  </div>
                  <div
                    data-aos="zoom-in"
                    data-aos-delay="800"
                    className="absolute inline-block bottom-1/4 left-1/4"
                  >
                    <StaticImage
                      src="../../images/support-avatar-4.png"
                      alt=""
                      className="object-cover border-2 border-white rounded-full w-9 h-9 tablet:w-12 tablet:h-12 "
                    />
                  </div>
                  <div
                    data-aos="zoom-in"
                    data-aos-delay="400"
                    className="absolute inline-block bottom-1/4 right-1/4"
                  >
                    <StaticImage
                      src="../../images/support-avatar-5.png"
                      alt=""
                      className="object-cover border-2 border-white rounded-full w-9 h-9 tablet:w-12 tablet:h-12 "
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* right-part */}
            <div className="">
              <div className="wrap">
                <div data-aos="fade-up">
                  <SectionTitle>
                    Make something great{" "}
                    <span className="text text-primary-600">together</span>
                  </SectionTitle>
                  <SectionText className="max-w-[494px]">
                    Quickly communicate enabled technology and turnkey
                    leadership skills. Uniquely enable accurate supply chains.
                  </SectionText>
                </div>
                <div
                  data-aos="fade-up"
                  className="flex gap-10 pt-2 pb-10 laptop:gap-14 laptop:pb-14"
                >
                  {/* mini-card */}
                  <div className="flex items-center justify-center gap-3">
                    <Telephone className="text-primary-600 text-display-xs" />
                    <div className="flex flex-col gap-[2px]">
                      <h6 className="font-medium text-body-xl laptop:text-display-xs text-neutral-700">
                        Voice Call
                      </h6>
                      <a href="tel:+8 2233 4111 00">
                        <TextSmall>+8 2233 4111 00</TextSmall>
                      </a>
                    </div>
                  </div>
                  {/* mini-card */}
                  <div className="flex items-center justify-center gap-3">
                    <CameraVideo className="text-primary-600 text-display-xs" />
                    <div className="flex flex-col gap-[2px]">
                      <h6 className="font-medium text-body-xl laptop:text-display-xs text-neutral-700">
                        Video Chat
                      </h6>

                      <TextSmall>Meet us anytime</TextSmall>
                    </div>
                  </div>
                </div>

                <div data-aos="fade-up" className="flex gap-5">
                  <Link to="#">
                    <Button size={"xl"}>Get Started</Button>
                  </Link>
                  <Link to="/blog-details">
                    <ButtonArrow
                      className="inline-block !pr-2"
                      text="Read article"
                    ></ButtonArrow>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Support1

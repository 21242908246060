import React from "react"
import { Alarm } from "react-bootstrap-icons"

import TextSmall from "../TextSmall"

const ApproachCard1 = ({
  title = "Visualize & plan",
  text = "Credibly reintermediate backend ideas for cross-platform models. Continually reintermediate.",
  icon = <Alarm />,
  className = "",
  iconClassName = "",
}) => {
  return (
    <>
      <div data-aos="fade-up">
        <div
          className={`flex items-center justify-center gap-6 px-8 py-8 bg-white shadow-lg rounded-2xl ${className}`}
        >
          <span
            className={`font-medium text-primary-500 text-display-xs ${iconClassName}`}
          >
            {icon}
          </span>
          {/* card-texts */}
          <div className="flex flex-col gap-3">
            <h6 className="font-medium text-body-xl tablet:text-display-xs text-neutral-700">
              {title}
            </h6>
            <TextSmall>{text}</TextSmall>
          </div>
        </div>
      </div>
    </>
  )
}
export default ApproachCard1

import React from "react"
import {
  Alarm,
  ColumnsGap,
  Hourglass,
  Lightbulb,
  Speedometer2,
  Tablet,
} from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import FeatureCard1 from "../../components/cards/FeatureCard1"

const Feature2 = () => {
  return (
    <>
      <Section className="desktop:pt-[180px]">
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col justify-center items-center gap-14 tablet:gap-[82px]">
            {/* top-part */}
            <div className="">
              <div
                data-aos="fade-in"
                className="flex flex-col items-center justify-center"
              >
                <SectionTitle className="text-center">
                  Step up to the{" "}
                  <span className="text text-primary-600">next </span>
                  level
                </SectionTitle>

                <SectionText className="max-w-[432px] !pb-0 text-center">
                  Efficiently unleash cross-media information without
                  cross-media value quickly maximize timely.
                </SectionText>
              </div>
            </div>

            {/* bottom-part */}
            <div className="">
              {/* cards */}
              <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-6 tablet:gap-[52px]  ">
                {/* card */}

                <FeatureCard1
                  title="Real-time analytics"
                  text="Seamlessly visualize quality intellectual capital without
                      superior collaboration and idea-sharing."
                  icon={<Alarm />}
                ></FeatureCard1>

                <FeatureCard1
                  title="Native apps"
                  text="Holistically pontificate installed base portals after Seamlessly visualize maintainable products."
                  icon={<Tablet />}
                  iconClassName="shadow-s-md text-secondary-600"
                ></FeatureCard1>

                <FeatureCard1
                  title="Easy for everyone"
                  text="Markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits."
                  icon={<Lightbulb />}
                  iconClassName="!shadow-p-md !text-primary-600"
                ></FeatureCard1>

                <FeatureCard1
                  title="Team collaboration"
                  text="Keeping your eye on the ball while the performing a deep dive on the start-up mentality to derive."
                  icon={<ColumnsGap />}
                  iconClassName="!shadow-p-md !text-primary-600"
                ></FeatureCard1>

                <FeatureCard1
                  title="Intuitive dashboard"
                  text="Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment."
                  icon={<Speedometer2 />}
                  iconClassName="!shadow-s-md text-warning-600"
                ></FeatureCard1>

                <FeatureCard1
                  title="24/7 Support"
                  text="Bring to the table win-win survival  the strategies to ensure proactive domination. At the end of the day, going forward."
                  icon={<Hourglass />}
                  iconClassName="shadow-p-md text-success-600"
                ></FeatureCard1>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Feature2

import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Trustpilot from "../../images/svg/trustpilot.svg"
import Capterra from "../../images/svg/capterra.svg"
import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import TextSmall from "../../components/TextSmall"
import SvgIcon from "../../images/svg/star-icon-90.svg"

const Hero2 = () => {
  return (
    <>
      <Section
        isHero
        className="overflow-hidden laptop:overflow-visible bg-neutral-100"
      >
        <Container>
          {/* section-wrap */}
          <div className="grid grid-cols-1 laptop:grid-cols-2">
            {/* left-part */}
            <div className="pt-10 tablet:pt-0">
              <div className="relative inline-block h-full laptop:w-full">
                <div className="left-0 laptop:overflow-visible laptop:absolute top-10">
                  <StaticImage
                    src="../../images/hero-2.png"
                    alt=""
                    className="rounded-3xl"
                  />
                  {/* card1 */}
                  <div className="absolute scale-[65%] tablet:scale-[85%] laptop:scale-100 top-0 right-0 translate-x-1/4  laptop:translate-x-[10%]  -translate-y-1/2  flex items-start justify-start shadow-lg gap-3 max-w-[360px] px-5 py-4 rounded-2xl bg-white">
                    <StaticImage
                      src="../../images/active-avatar-1.png"
                      alt=""
                      className="!object-contain w-10 h-10 shrink-0"
                    />
                    <div className="flex flex-col justify-start items-start gap-[6px]">
                      <div className="flex items-center justify-between w-full">
                        <p className="font-medium text-body-sm text-neutral-800">
                          Roy C. Gentry
                        </p>
                        <p className="font-normal text-body-xs text-neutral-500">
                          Sunday 4:20pm
                        </p>
                      </div>
                      <div className="px-3 py-2 rounded-lg bg-neutral-50">
                        <p className="font-normal text-body-sm text-neutral-500">
                          Hey <span className="text-primary-600">@Lacky, </span>
                          could you please check the latest file ASAP?
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* card2 */}
                  <div className="absolute scale-[65%] tablet:scale-[85%] laptop:scale-100 left-0 translate-y-1/2 laptop:-translate-y-[65%] bottom-0 -translate-x-1/4 laptop:-translate-x-[10%]  flex items-start justify-start shadow-lg gap-3 max-w-[360px] px-5 py-4 rounded-2xl bg-white">
                    <StaticImage
                      src="../../images/active-avatar-2.png"
                      alt=""
                      className="object-cover w-10 h-10"
                    />
                    <div className="flex flex-col justify-start items-start gap-[6px]">
                      <div className="flex items-center justify-between w-full">
                        <p className="font-medium text-body-sm text-neutral-800">
                          Lakcy Diana
                        </p>
                        <p className="font-normal text-body-xs text-neutral-500">
                          Monday 9:40pm
                        </p>
                      </div>
                      <div className="px-3 py-2 rounded-lg bg-neutral-50">
                        <p className="font-normal text-body-sm text-neutral-500">
                          {`Just checked. Looks great so far :)`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* right-part */}
            <div className="pt-24 laptop:pl-24 laptop:pt-16">
              <SectionTitle isHero={true}>
                Let’s put the team back in{" "}
                <span className="text text-primary-600">teamwork</span>
              </SectionTitle>
              <SectionText isHero={true}>
                Kloft provides everything you need to build a stunning website
                for your business. We take care of both the performance and
                aesthetic aspects.
              </SectionText>
              <Link to="/sign-up">
                <Button className="!border-none" size={"2xl"}>
                  Sign up for free
                </Button>
              </Link>
              {/* bottom-wrap */}
              <div className="flex flex-col gap-8 pt-8 laptop:pt-14">
                {/* top */}
                <div className="flex items-center justify-start gap-3">
                  {/* avaters */}
                  <div className="flex ">
                    <StaticImage
                      src="../../images/avatar-1.png"
                      alt=""
                      className="object-contain w-10 h-10 border-2 border-white rounded-full "
                    />
                    <StaticImage
                      src="../../images/avatar-2.png"
                      alt=""
                      className="relative object-contain w-10 h-10 border-2 border-white rounded-full -left-3"
                    />
                    <StaticImage
                      src="../../images/avatar-3.png"
                      alt=""
                      className="relative object-contain w-10 h-10 border-2 border-white rounded-full -left-5"
                    />
                    <div className="relative flex items-center justify-center w-10 h-10 border-2 border-white rounded-full -left-8 bg-primary-50">
                      <TextSmall className="!font-medium text-primary-600">
                        +9k
                      </TextSmall>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-1">
                    <TextSmall className="!font-medium text-neutral-800">
                      Our happy users
                    </TextSmall>
                    <div className="flex gap-[6px]">
                      <SvgIcon className="" />
                      <p className="font-medium text-body-xs text-neutral-700">
                        4.95
                      </p>
                      <p className="font-normal text-body-xs text-neutral-500">
                        (9k review)
                      </p>
                    </div>
                  </div>
                </div>
                {/* bottom */}
                <div className="flex pt-2 gap-11">
                  <a
                    href="https://www.trustpilot.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Trustpilot></Trustpilot>
                  </a>
                  <a
                    href="https://www.capterra.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Capterra></Capterra>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Hero2

import React from "react"

import BrandLogoLight1 from "../../images/svg/brand-logo-light-1.svg"
import BrandLogoLight2 from "../../images/svg/brand-logo-light-2.svg"
import BrandLogoLight3 from "../../images/svg/brand-logo-light-3.svg"
import BrandLogoLight4 from "../../images/svg/brand-logo-light-4.svg"
import BrandLogoLight5 from "../../images/svg/brand-logo-light-5.svg"
import BrandLogoLight6 from "../../images/svg/brand-logo-light-6.svg"
import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionText from "../../components/SectionText"

const Brand2 = () => {
  return (
    <>
      <Section className="bg-neutral-900">
        <Container>
          {/* wrap */}
          <div className="grid items-center justify-between grid-cols-1 gap-10 laptop:gap-20 laptop:grid-cols-2">
            {/* left-part */}
            <div className="">
              <div
                className="flex justify-center laptop:justify-end items-center  laptop:items-start flex-wrap  gap-8
             tablet:gap-11 max-w-[600px]"
              >
                <div data-aos="zoom-in">
                  <BrandLogoLight1 className="!w-[128px]"></BrandLogoLight1>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogoLight2 className="!w-[128px]"></BrandLogoLight2>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogoLight3 className="!w-[128px]"></BrandLogoLight3>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogoLight4 className="!w-[128px]"></BrandLogoLight4>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogoLight5 className="!w-[128px]"></BrandLogoLight5>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogoLight6 className="!w-[128px]"></BrandLogoLight6>
                </div>
              </div>
            </div>
            {/* right-part */}
            <div className="flex items-center justify-center">
              <SectionText className="max-w-[340px] text-neutral-400 !pb-0 text-center laptop:text-left">
                Teams around the world creating meaningful communication.
              </SectionText>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Brand2

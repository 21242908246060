import React from "react"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"

const Newsletter1 = () => {
  return (
    <>
      <Section className="overflow-hidden">
        <Container>
          {/* section-wrap */}
          <div className="relative max-w-[1000px] mx-auto">
            <div
              data-aos="zoom-in"
              className="flex flex-col items-center justify-center px-5 tablet:px-14 py-5 laptop:px-[100px] tablet:py-14 laptop:py-[100px] bg-secondary-50 rounded-3xl rounded-tr-[0px]"
            >
              <SectionTitle className="!text-center">
                {" "}
                Have more{" "}
                <span className="text text-primary-600">questions</span>
              </SectionTitle>
              <SectionText className="tablet:w-[680px] text-center">
                We’ve over 20 years of experience, if you’re looking to revive
                the brands your team has shared, or your putting together a new
                virtual team we’re here to help
              </SectionText>
              <Link to="/contact-1">
                <Button size={"xl"}>Let’s Talk</Button>
              </Link>
            </div>
            <div className="absolute top-0 right-0 z-20 inline-block translate-x-full -translate-y-full">
              <div
                data-aos="fade-down-left"
                data-aos-delay="400"
                className=" bg-secondary-50 w-[80px] h-[80px] "
              >
                <div className="absolute w-8 h-8 bg-primary-100 top-full left-full"></div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 inline-block -translate-x-1/2 translate-y-1/2">
              <div
                data-aos="fade-up-right"
                data-aos-delay="500"
                className="tablet:h-[100px] w-16 h-16 tablet:w-[100px] bg-neutral-50 "
              ></div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Newsletter1
